import { render, staticRenderFns } from "./default.vue?vue&type=template&id=c6616498&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=c6616498&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6616498",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/codebuild/output/src4043319836/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/header/index.vue').default,Spinner: require('/codebuild/output/src4043319836/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/spinner/index.vue').default,Toast: require('/codebuild/output/src4043319836/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/toast/index.vue').default,Footer: require('/codebuild/output/src4043319836/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/footer/index.vue').default,ScrollButton: require('/codebuild/output/src4043319836/src/codestar-connections.us-west-2.amazonaws.com/git-http/384501692319/us-west-2/b627004c-6cc6-4388-b3d2-d28189087d6c/bode-canada/bode-client/components/scroll-button/ScrollButton.vue').default})
